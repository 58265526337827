<template>
  <div>
    <t-collapse :compact-header="true">
      <template #header><span>Global Fees</span></template>
      <b-form-group class="float-left mr-4" label="Global % Fee">
        <percentage-input @input="updateProposal()" v-model="configuration.globalPercentageFee"></percentage-input>
      </b-form-group>
      <b-form-group class="float-left" label="Global Static Fee">
        <b-input @change="updateProposal()" v-model="configuration.globalStaticFee"></b-input>
      </b-form-group>
      <div class="clearfix"></div>
    </t-collapse>
    <t-collapse :compact-header="true">
      <template #header><span>Ideal Media Mix</span></template>
      <template v-for="(mediaValue, mediaKey) in configuration.idealMediaMix" :key="mediaKey">
        <b-form-group  :label="mediaKey" class="float-left mr-4">
          <percentage-input @input="updateProposal()" v-model="configuration.idealMediaMix[mediaKey]"/>
        </b-form-group>
      </template>
      <div class="clearfix"></div>
    </t-collapse>
    <t-collapse :compact-header="true">
      <template #header><span>Text for Sections</span></template>
      <template v-for="(section, sectionKey) in configuration.sectionText" :key="sectionKey">
        <b-form-group :label="sectionKey">
          <b-textarea @change="updateProposal()" rows="4" v-model="configuration.sectionText[sectionKey]"></b-textarea>
        </b-form-group>
      </template>
    </t-collapse>
  </div>
</template>

<script>
import PercentageInput from 'core-ui/components/inputs/percentageInput'
import _ from 'lodash'
export default {
  name: 'proposalConfiguration',
  components: { PercentageInput },
  props: ['value'],
  data () {
    return {
      configuration: this.value
    }
  },
  watch: {
    value (newValue, oldValue) {
      this.$setCompat(this, 'configuration', this.value)
    }
  },
  methods: {
    updateProposal () {
      let v = this
      this.debouncedEmit(this.configuration)
    },
    debouncedEmit: _.debounce(function (val) {
      this.$emit('update', val)
    }, 2000)
  }
}
</script>

<style scoped>

</style>
