<template>
  <div>
    <template v-if="typeof value !== 'object'">
      <b-form-group>
        <template #label><div>{{$filters.convertFromCamelCase(referenceKey)}}</div></template>
        <b-form-input :value="value" @change="$emit('change', $event)" @input="$emit('input', $event)"></b-form-input>
      </b-form-group>
    </template>
    <template v-else>
      <t-collapse>
        <template #header><span>{{$filters.convertFromCamelCase(referenceKey)}}</span></template>
        <template v-for="(configVal, configKey) in value" :key="configKey">
          <proposal-configuration-editor :reference-key="configKey" :value="configVal" @input="$emit('input', $event)"/>
        </template>
      </t-collapse>
    </template>
  </div>
</template>

<script>
export default {
  name: 'proposalConfigurationEditor',
  props: ['value', 'referenceKey', 'exclusions']
}
</script>

<style scoped>

</style>
