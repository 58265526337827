<template>
  <div>
    <t-collapse v-if="edits.requestedQuery">
      <template #header><span>General</span></template>
      <div class="row">
        <b-form-group class="col" label="Email Address">
          <b-input v-model="edits.requestedQuery.preparer" @update="fireUpdate($event)"/>
        </b-form-group>
        <b-form-group class="col" label="Prospect Name">
          <b-input v-model="edits.requestedQuery.customerName" @update="fireUpdate($event)"/>
        </b-form-group>
      </div>
    </t-collapse>
    <t-collapse>
      <template #header><span>Section Text</span></template>
      <div>
        <prospect-edit-section-text :value="edits.sectionText"
                                    @input="edits.sectionText = $event; fireUpdate($event)"/>
      </div>
    </t-collapse>
    <t-collapse v-if="edits.address">
      <template #header><span>Where Are You Going With This</span></template>
      <b-form-group label="address">
        <b-input v-model="edits.address.address1" @update="fireUpdate($event)"/>
      </b-form-group>
      <div class="row">
        <b-form-group :label="stateVerbiage" class="col">
          <b-input v-model="edits.address.state" @update="fireUpdate($event)"/>
        </b-form-group>
        <b-form-group class="col" label="Postal Code">
          <b-input v-model="edits.address.postalCode" @update="fireUpdate($event)"/>
        </b-form-group>
        <b-form-group class="col" label="Country">
          <b-input v-model="edits.country" @update="fireUpdate($event)"/>
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group class="col" label="County">
          <b-input v-model="edits.address.county" @update="fireUpdate($event)"/>
        </b-form-group>
        <b-form-group class="col" label="Neighborhood">
          <b-input v-model="edits.address.neighborhood" @update="fireUpdate($event)"/>
        </b-form-group>
        <b-form-group class="col" label="Radius">
          <b-input v-model="edits.radius" @update="fireUpdate($event)"/>
        </b-form-group>
      </div>
    </t-collapse>
    <t-collapse v-if="edits.lowRecommendation">
      <template #header><span>Recommendations</span></template>
      <div class="form-row">
        <div class="col-sm-2">&nbsp;</div>
        <div class="col">Good</div>
        <div class="col">Better</div>
        <div class="col">Best</div>
      </div>
      <div class="form-row">
        <div class="col-sm-2">Cost</div>
        <div class="col"><b-input v-model="edits.lowRecommendation.cost" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.midRecommendation.cost" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.highRecommendation.cost" @update="fireUpdate($event)"/></div>
      </div>
      <div class="form-row">
        <div class="col-sm-2">Clicks</div>
        <div class="col"><b-input v-model="edits.lowRecommendation.clicks" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.midRecommendation.clicks" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.highRecommendation.clicks" @update="fireUpdate($event)"/></div>
      </div>
      <div class="form-row">
        <div class="col-sm-2">Conv</div>
        <div class="col"><b-input v-model="edits.lowRecommendation.conversions" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.midRecommendation.conversions" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.highRecommendation.conversions" @update="fireUpdate($event)"/></div>
      </div>
      <div class="form-row">
        <div class="col-sm-2">CPC</div>
        <div class="col"><b-input v-model="edits.lowRecommendation.cpc" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.midRecommendation.cpc" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.highRecommendation.cpc" @update="fireUpdate($event)"/></div>
      </div>
      <div class="form-row">
        <div class="col-sm-2">CPL</div>
        <div class="col"><b-input v-model="edits.lowRecommendation.cpl" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.midRecommendation.cpl" @update="fireUpdate($event)"/></div>
        <div class="col"><b-input v-model="edits.highRecommendation.cpl" @update="fireUpdate($event)"/></div>
      </div>
    </t-collapse>
    <t-collapse>
      <template #header><span>Why These Recommendations</span></template>
      <div class="row">
        <b-form-group class="col" label="DMA">
          <b-input v-model="edits.dma" @update="fireUpdate($event)"/>
        </b-form-group>
        <!-- <prospect-markup v-model="edits.requestedQuery" @input="fireUpdate($event)"/> -->
      </div>
      <div class="row">
        <b-form-group class="col" label="No. of Campaign Measurements">
          <b-input v-model="edits.measurements" @update="fireUpdate($event)"/>
        </b-form-group>
        <!-- <b-form-group class="col" label="No. of Postal Codes">
          <b-input v-model="edits.postalCodeCount" @update="fireUpdate($event)"/>
        </b-form-group> -->
      </div>
    </t-collapse>
    <t-collapse>
      <template #header><span>Market Analysis</span></template>
      <div class="row">
        <b-form-group class="col" label="Spend/Person">
          <b-input v-model="edits.overallAverageSpendPerPersonTargeted" @update="fireUpdate($event)"/>
        </b-form-group>
      </div>
    </t-collapse>
    <t-collapse>
      <template #header><span>Blueprint</span></template>
      <div>
        <prospect-checkbox-map v-model="edits.blueprintsIncluded" :eligible-blueprints="edits.allEligibleBlueprints" @input="fireUpdate($event)"/>
      </div>
    </t-collapse>
    <t-collapse>
      <template #header><span>Allocations By Channel</span></template>
      <div>
        <prospect-allocations-by-channel v-model="edits.allocationByChannel" @input="fireUpdate($event)"/>
      </div>
    </t-collapse>
    <t-collapse>
      <template #header><span>Targeted Postal Codes</span></template>
      <div>
        <prospect-targeted-postal-codes-editor v-model="edits.targetedPostalCodes" @input="fireUpdate($event)"/>
      </div>
    </t-collapse>
  </div>
</template>

<script>
import ProposalConfigurationEditor from '@/components/pages/prospecting/proposalConfigurationEditor'
import ProspectConfigurationBasic from '@/components/pages/prospecting/prospectConfigurationBasic'
import PercentageInput from 'core-ui/components/inputs/percentageInput'
import CurrencyInput from 'core-ui/components/inputs/currencyInput'
import ProspectPostalCodeEditor from '@/components/pages/prospecting/prospectPostalCodeEditor'
import ProspectCheckboxMap from './prospectCheckboxMap'
import ProspectAllocationsByChannel from '@/components/pages/prospecting/prospectAllocationsByChannel'
import ProspectTargetedPostalCodesEditor from '@/components/pages/prospecting/prospectTargetedPostalCodesEditor'
import ProspectEditSectionText from '@/components/pages/prospecting/prospectEditSectionText'
import _ from 'lodash'

export default {
  name: 'prospectConfiguration',
  components: { ProspectEditSectionText, ProspectTargetedPostalCodesEditor, ProspectAllocationsByChannel, ProspectCheckboxMap, ProspectPostalCodeEditor, CurrencyInput, PercentageInput, ProspectConfigurationBasic, ProposalConfigurationEditor },
  props: ['value', 'loadingPdf'],
  data () {
    return {
      edits: {},
      allocationsByChannel: {},
      excluded: ['prospectingProposalPdfLink', 'pathToProposal', 'estimatedPopulation', 'overallAverageSpendPerPersonTargeted', 'dma', 'measurements', 'postalCodeCount', 'topPostalCodeCount', 'topFivePercent' , 'radius']
    }
  },
  watch: {
    value: {
      handler (newValue, oldValue) {
        this.$setCompat(this, 'edits', newValue)
      },
      immediate: true
    },
    blueprintsIncluded (val) {
      // this.edits.generatedContentTemplateIds
    }
  },
  computed: {
    stateVerbiage () {
      if (this.edits?.country?.toLowerCase().startsWith('ca')) {
        return 'Province'
      } else {
        return 'State'
      }
    },
    blueprintsIncluded () {
      return this.edits.blueprintsIncluded
    }
  },
  methods: {
    fireUpdate () {
      this.$emit('update:loadingPdf', true)
      this.emitUpdate()
    },
    emitUpdate: _.debounce(function (val) {
      if (this.edits) {
        const addy = this.edits.address
        this.edits.requestedQuery.address = `${addy.address1}, ${addy.city}, ${addy.state} ${addy.postalCode}, ${addy.country}`
        this.$emit('update', this.edits)
        this.$emit('update:loadingPdf', false)
      }
    }, 1200),
    processEdits (obj) {
      const newObj = _.cloneDeep(obj)
      newObj.address = JSON.parse(obj.address)
      return newObj
    }
  }
}
</script>

<style scoped>

</style>
